.breadcrumbs {
	//&__inner
	//	width: 800px
	//	margin: auto
	z-index: 5;
	margin-bottom: 45px;

	&__list {
		display: flex;
		list-style: none;
		justify-content: center; }

	&__item {
		&:last-child {
			& .fa {
				display: none; } }

		& .fa-angle-right {
			color: $colorGreen; }

		& .fa {
			padding: 0 8px; } }

	&__link {
	  color: $colorTextColor; } }
