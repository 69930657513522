.dropdown {
	position: relative;
	&__title {
		width: 188px;
		background-color: #fff;
		border-radius: 4px;
		text-align: left;
		position: absolute;
		top: 100%;
		left: 74px;
		padding: 4px 0 6px 19px;
		display: none;
		z-index: 1; } }


.dropdown-menu {
	display: none;
	list-style: non;
	position: absolute;
	top: 0;
	right: 0;
	float: left;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0,0,0,.15);
	border-left: 0;
	border-right: 0;
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
	box-shadow: 0 6px 12px rgba(0,0,0,.175);
	width: 190px;
	border-top: none;
	z-index: 6; }

.dropdown.open .dropdown-menu,
.dropdown.open .dropdown__title {
	display: block; }

.dropdown-menu > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: 400;
	line-height: 1.42857143;
	color: #333;
	white-space: nowrap;
	transition: .1s;
	&:hover {
		background-color: $colorGreen;
		color: #fff; } }

.dropdown-toggle {
	z-index: 7;
	position: relative;
	width: 190px; }

ul.dropdown-menu::before {
	content: "Выберите город:";
	border-bottom: 1px #eee solid;
	width: 100%;
	display: block;
	padding: 50px 20px 10px;
	font-weight: bold;
	margin: 0 0 5px 0; }
