@import "fonts";
@import "vars";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }

body input:required:valid,
body textarea:required:valid {
	color: #666; }

html, body {
	height: 100%; }

body {
	font-size: $base-font-size;
	min-width: 320px;
	position: relative;
	line-height: 1.42;
	font-family: $default-font;
	overflow-x: hidden;
	opacity: 1; }

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto; }

section {
	position: relative; }

button {
	&:active {
		outline: none !important; }
	&:focus {
		outline: 0; } }

.link {
	border-bottom: 1px solid transparent;
	transition: all .3s;
	& span {
		display: inline-block;
		vertical-align: middle;
		border-bottom: 1px solid transparent;
		&:hover {
			color: $colorGreen; } } }

.link--white {
	color: $colorWhite;
	& span {
		border-bottom-color: rgba(255, 255, 255, .3); } }


.link--white:hover {
	color: $colorGreen;
	& span {
		border-bottom-color: $colorGreen; } }


.link--black {
	color: $colorBlack;
	& span {
		border-bottom-color: rgba(0, 0, 0, .2); } }

.link--black:hover {
	color: $colorGreen;
	& span {
		border-bottom-color: $colorGreen; } }

.link--gray {
	color: $colorTextColor;
	& span {
		border-bottom-color: rgba(0, 0, 0, .4); } }

.link--gray:hover {
	color: $colorGreen;
	& span {
		border-bottom-color: $colorGreen; } }

.active span:hover {
	color: #fff; }

.btn {
	padding: 15px 17px 14px 24px;
	border: none;
	background: transparent;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;

	& i {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px; }
	&__text {
		font-family: Arial, sans-serif;
		display: inline-block;
		vertical-align: middle; }

	&--green {
		background: $colorGreen;
		color: $colorWhite !important;
		box-shadow: 0 3px 0 $colorDeepGreen;
		&:hover {
			background-color: #7AD66C; }
		&:active {
			background-color: #57C747;
			box-shadow: 0 3px 0 #57C747; }

		& i,
		&__text {
			color: $colorWhite; } }

	&--transparent {
		padding: 17px 24px 12px;
		border: 1px solid $colorWhite;
		border-radius: 5px;
		color: $colorWhite;
		box-shadow: none;
		font-size: $base-font-size - 2;

		& i {
			margin-right: 10px; } }

	&--lessons {
		min-width: 174px;
		padding-top: 9px;
		padding-bottom: 12px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%); } }

.blackout {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(1, 44, 54, .6); }
//z-index: -1

.title-wrapper {
	z-index: 5; }

.heading {
	font-size: 49px;
	margin-top: 0;
	color: $colorBlack;
	&--main {
		color: $colorWhite;
		font-size: 69px;
		line-height: 69px;
		margin-bottom: 39px; }

	&--about {
		font-weight: 400;
		position: relative;
		margin-bottom: 24px;

		&:before {
			content: '';
			position: absolute;
			width: 45px;
			height: 6px;
			display: block;
			top: -5px;
			left: 0;
			background: $colorGreen; } } }

.text {
	font-size: $base-font-size + 2;
	line-height: 27px;
	font-family: Arial, sans-serif;
	color: $colorTextColor; }

.main-wrapper {
	height: 100%; }

.wrapper {
	min-height: 100%;
	position: relative;
	&:after {
		content: "";
		display: block;
		height: 270px;
		overflow: hidden; } }

* {
	-webkit-font-smoothing: antialiased; }

@import "sections/_top-section";
@import "sections/_program-selection";
@import "sections/_about";
@import "sections/_unique-center";
@import "sections/_play-and-learn";
@import "sections/_lessons";
@import "sections/_mega-reading";
@import "sections/_mental-math";
@import "sections/_gallery";
@import "sections/_reviews";
@import "sections/_footer";
@import "sections/_map";
@import "sections/_inner-page-header.sass";
@import "sections/_breadcrumbs.sass";
@import "sections/_aside.sass";
@import "sections/_blockquote.sass";
@import "sections/_inner-page-content.sass";
@import "sections/_inner-page-bottom.sass";
@import "_dropdown.sass";
@import "media";
// Всегда в конце
