.footer {
	padding: 56px 0 40px;
	height: 310px;
	margin-top: -310px;
	position: relative;
	background: #3E3E3E;
	&__social {
		& .social__item {
			width: 33%; }

		& .social__link {
			color: #CCCCCC;
			&:hover {
				color: $colorWhite; } } }

	&__sub-heading {
		display: block;
		margin-bottom: 15px;
		font-size: $base-font-size - 2;
		color: #CCCCCC;
		line-height: 16px;
		font-weight: bold;
		font-family: Arail, sans-serif; }

	&__link {
		display: block;
		margin-bottom: 10px;
		font-size: 12px;
		font-family: Arial, sans-serif;
		line-height: 14px;
		font-weight: 400;
		color: #CCCCCC; }

	&__address {
		@extend .footer__link;
		display: inline-block; }

	&__tel {
		@extend .footer__link;
		display: inline-block; }

	& .logo {
		width: 120px;
		margin-bottom: 20px;

		&__img {
			width: 40px;
			margin-right: 8px;
			opacity: .65; }
		&__text {
			width: 71px;
			font-size: 11px;
			color: #CCCCCC;
			line-height: 11px; } } }

.feed {
	&__heading {
		@extend .footer__sub-heading; }

	&__form {
		position: relative; }

	&__text {
		@extend .footer__link;
		line-height: 18px; }

	&__input {
		width: 100%;
		display: block;
		border: none;
		background: #666666;
		padding: 13px 20px;
		color: $colorWhite;
		outline: none;
		border-radius: 5px;
		&::placeholder {
			color: #CCCCCC;
			font-family: Arial, sans-serif;
			font-weight: 400; } }

	&__submit {
		position: absolute;
		top: 8px;
		right: 5px;
		background: transparent;
		outline: none;
		border: none;
		color: $colorWhite;
		font-size: 20px; } }

.copyrights {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid $colorGray;

	&__text {
		@extend .footer__link;
		color: #737373;
		line-height: 18px; }

	&__right {
		text-align: right;
		font-size: 12px;
		font-family: Arial, sans-serif;
		line-height: 18px;
		font-weight: 400;
		color: #737373;
		& .link {
			color: rgba(115, 115, 115, 1);
			border-bottom: 1px solid rgba(115, 115, 115, .5);
			&:hover {
				color: $colorWhite;
				border-color: $colorWhite; } } } }
