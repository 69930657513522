.lessons {
	position: relative;
	padding: 85px 0 89px;
	margin-bottom: 70px;
	background: url('/img/lessons/lessons.jpg') no-repeat center;
	background-size: cover;
	-webkit-clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%);
	clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%);

	&__block {
		position: relative;
		padding-bottom: 100px;
		min-height: 400px; }

	&__heading {
		margin-top: 0;
		margin-bottom: 10px;
		text-align: center;
		color: $colorWhite;
		font-size: 30px;
		line-height: 1.1;
		font-weight: 300;
		position: relative;
		padding-bottom: 23px;

		&:after {
			content: '';
			position: absolute;
			width: 40px;
			height: 6px;
			display: block;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			background: $colorGreen; } }

	&__list {
		color: $colorWhite;
		margin: 0;
		padding: 0;
		list-style-type: none; }

	&__item {
		text-align: center;
		padding: 3px 0;
		&:first-child {
			padding-top: 0; }
		&:last-child {
			padding-bottom: 0; } }

	&__link {
		font-size: $base-font-size + 2; } }
