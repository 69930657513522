.reviews-container {
  margin-top: 56px;
  padding-bottom: 52px; }

.reviews {
  padding-top: 90px;

  &__heading {
    @extend .gallery__heading;
    margin-bottom: 0;
    font-weight: 400;

    &:after {
      @extend .gallery__heading:after; } }
  &__all {
    text-align: right; }

  &__text {
    @extend .gallery__text; }

  &__btn {
    @extend .gallery__btn; }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none; } }

.review {

  &--text {
    //padding-right: 20px
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dddddd;

    &:last-child {
      border-bottom: 0; } }

  &--video {
    margin: 10px 0;
    &:first-child {
      margin-top: 0; }
    &:last-child {
      margin-bottom: 0; } }

  &__name {
    display: inline-block;
    vertical-align: middle;
    color: $colorBlack;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400; }

  &__info-btn {
    line-height: 30px;
    font-size: 20px;
    display: inline-block;
    vertical-align: text-top;
    margin-left: 25px;
    color: $colorGreen;
    cursor: pointer; }

  &__text {
    font-size: $base-font-size;
    color: $colorTextColor;
    line-height: 24px;
    font-family: Arial, sans-serif; }

  &__video {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }

  &__video-left {
    width: 98px;
    margin-right: 20px; }

  &__video-right {
    width: 325px; }

  &__video-descr {
    display: inline-block;
    margin-top: 10px;
    font-size: $base-font-size - 2;
    color: $colorTextColor;
    line-height: 24px; } }

.play-btn {
  border: none;
  background-color: transparent;

  & i {
    color: $colorGreen;
    font-size: 115px; } }
