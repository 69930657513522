.play-and-learn {
	margin-bottom: 105px;

	&__heading {
		@extend .numbers-block__heading;
		color: $colorBlack;
		margin-bottom: 50px;
		margin-top: 0; }

	&__left,
	&__right {
		& .text {
			font-size: $base-font-size + 2;
			line-height: 1.2;
			text-align: center; } }

	&__text {
		max-width: 700px;
		margin: 0 auto 34px;
		text-align: center;
		font-size: $base-font-size + 2; } }

.video-block {
	position: relative;
	margin-bottom: 30px;

	&__inner {
		position: absolute;
		bottom: 30px;
		left: 30px;

		& .fa {
			color: $colorGreen; } }

	&__img {
		display: block;
		height: 100%;
		width: auto;
		max-width: 100%; } }

.photo-block {
	&__img {
		display: block;
		height: 100%;
		width: auto;
		max-width: 100%; }

	&__list {
		margin: -15px 0 15px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
 }		//align-items: center

	&__list-item {
		margin: 15px;
		width: 151px;
		height: 151px;
		border-radius: 3px;
		overflow: hidden;
		position: relative;
		cursor: pointer;

		&:hover .photo-block__item-overlay {
			opacity: 1; } }

	&__item-overlay {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 5px solid $colorGreen;
		border-radius: 5px;

		opacity: 0;
		transition: opacity .3s;

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .3);

		& i {
			height: 40px;
			width: 40px;
			background: rgba(255, 255, 255, .2);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $colorWhite; } } }
