blockquote {
	background-color: #F6F7F8;
	padding: 30px;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0;
	padding-bottom: 10px;
	font-style: italic;
	line-height: 24px;
	font-size: 16px;
	font-family: "Arial", sans-serif; }
