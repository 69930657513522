@charset "UTF-8";
@font-face {
  font-family: "fontawesome";
  font-weight: normal;
  font-style: normal;
  src: font-url("/app/fonts/FontAwesome.eot");
  src: font-url("/app/fonts/FontAwesome.eot?#iefix") format("embedded-opentype"), font-url("/app/fonts/FontAwesome.woff") format("woff"), font-url("/app/fonts/FontAwesome.ttf") format("truetype"); }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1; }

*:-moz-placeholder {
  color: #666;
  opacity: 1; }

*::-moz-placeholder {
  color: #666;
  opacity: 1; }

*:-ms-input-placeholder {
  color: #666;
  opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: #666; }

body input:required:valid,
body textarea:required:valid {
  color: #666; }

html, body {
  height: 100%; }

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.42;
  font-family: "Exo 2", sans-serif;
  overflow-x: hidden;
  opacity: 1; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

section {
  position: relative; }

button:active {
  outline: none !important; }

button:focus {
  outline: 0; }

.link {
  border-bottom: 1px solid transparent;
  transition: all .3s; }
  .link span {
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid transparent; }
    .link span:hover {
      color: #57C747; }

.link--white {
  color: #ffffff; }
  .link--white span {
    border-bottom-color: rgba(255, 255, 255, 0.3); }

.link--white:hover {
  color: #57C747; }
  .link--white:hover span {
    border-bottom-color: #57C747; }

.link--black {
  color: #000000; }
  .link--black span {
    border-bottom-color: rgba(0, 0, 0, 0.2); }

.link--black:hover {
  color: #57C747; }
  .link--black:hover span {
    border-bottom-color: #57C747; }

.link--gray {
  color: #555555; }
  .link--gray span {
    border-bottom-color: rgba(0, 0, 0, 0.4); }

.link--gray:hover {
  color: #57C747; }
  .link--gray:hover span {
    border-bottom-color: #57C747; }

.active span:hover {
  color: #fff; }

.btn {
  padding: 15px 17px 14px 24px;
  border: none;
  background: transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
  .btn i {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .btn__text {
    font-family: Arial, sans-serif;
    display: inline-block;
    vertical-align: middle; }
  .btn--green {
    background: #57C747;
    color: #ffffff !important;
    box-shadow: 0 3px 0 #3EB12D; }
    .btn--green:hover {
      background-color: #7AD66C; }
    .btn--green:active {
      background-color: #57C747;
      box-shadow: 0 3px 0 #57C747; }
    .btn--green i, .btn--green__text {
      color: #ffffff; }
  .btn--transparent {
    padding: 17px 24px 12px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    box-shadow: none;
    font-size: 14px; }
    .btn--transparent i {
      margin-right: 10px; }
  .btn--lessons {
    min-width: 174px;
    padding-top: 9px;
    padding-bottom: 12px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }

.blackout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(1, 44, 54, 0.6); }

.title-wrapper {
  z-index: 5; }

.heading {
  font-size: 49px;
  margin-top: 0;
  color: #000000; }
  .heading--main {
    color: #ffffff;
    font-size: 69px;
    line-height: 69px;
    margin-bottom: 39px; }
  .heading--about {
    font-weight: 400;
    position: relative;
    margin-bottom: 24px; }
    .heading--about:before {
      content: '';
      position: absolute;
      width: 45px;
      height: 6px;
      display: block;
      top: -5px;
      left: 0;
      background: #57C747; }

.text {
  font-size: 18px;
  line-height: 27px;
  font-family: Arial, sans-serif;
  color: #555555; }

.main-wrapper {
  height: 100%; }

.wrapper {
  min-height: 100%;
  position: relative; }
  .wrapper:after {
    content: "";
    display: block;
    height: 270px;
    overflow: hidden; }

* {
  -webkit-font-smoothing: antialiased; }

.top-section {
  padding-bottom: 250px;
  background: url("/img/bg-main.jpg") no-repeat center;
  background-size: cover; }

.top-info-block {
  margin-bottom: 56px; }
  .top-info-block__item {
    text-align: right; }
  .top-info-block__link {
    padding-top: 14px;
    display: block;
    font-size: 15px !important;
    line-height: 1;
    vertical-align: text-bottom;
    font-size: 17px;
    color: #57C747; }
    .top-info-block__link--btn .fa {
      color: #ffffff; }
  .top-info-block__text {
    margin-left: 16px;
    font-size: 15px;
    color: #ffffff;
    transition: .3s; }
    .top-info-block__text:hover {
      color: #57C747; }
  .top-info-block .btn {
    margin-left: auto; }
    .top-info-block .btn .fa-map-marker {
      margin-right: 13px; }
    .top-info-block .btn .btn__text {
      margin-right: 9px; }

.header {
  margin-bottom: 46px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.logo {
  width: 260px; }
  .logo__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
  .logo__img {
    width: 80px;
    margin-right: 20px; }
  .logo__text {
    display: inline-block;
    width: 160px;
    font-size: 25px;
    line-height: 1;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold; }

.main-nav__list {
  padding: 8px 0 0 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }

.main-nav__item {
  margin: 0 4px; }
  .main-nav__item:last-child {
    margin-right: 0; }
  .main-nav__item:first-child {
    margin-left: 0; }

.main-nav__link {
  display: block;
  padding: 5px 13px 5px 13px !important;
  line-height: 17px;
  font-size: 15px; }
  .main-nav__link.active {
    background: #57C747;
    color: #ffffff !important;
    box-shadow: 0 3px 0 #3EB12D;
    border-radius: 3px; }
    .main-nav__link.active span {
      border: none; }

.main-nav .dropdown {
  z-index: 7; }
  .main-nav .dropdown-toggle {
    width: auto; }
    .main-nav .dropdown-toggle:focus {
      outline: none; }
  .main-nav .dropdown-menu {
    top: 100%;
    left: 0;
    right: auto;
    width: auto; }
    .main-nav .dropdown-menu:before {
      display: none; }

.title-wrapper {
  max-width: 650px;
  margin: 0 auto 30px;
  text-align: center;
  position: relative; }

.social-video-block {
  max-width: 650px;
  padding-left: 51px;
  margin: auto;
  display: flex;
  flex-wrap: wrap; }

.social {
  width: 191px;
  margin-right: 41px;
  display: flex;
  align-items: center; }
  .social__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .social__link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    transition: .2s; }
    .social__link:hover {
      background: #57C747;
      color: #000000 #ffffff; }

.video {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .video__link {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .video__text a {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.8); }
  .video:hover .video__text span {
    color: #57C747;
    border-bottom-color: #57C747; }
  .video__btn {
    margin-right: 25px;
    color: #ffffff; }
    .video__btn a i {
      font-size: 96px;
      line-height: 96px;
      color: #ffffff; }
  .video__text {
    font-size: 15px;
    line-height: 23px;
    max-width: 138px;
    color: #ffffff; }
    .video__text span {
      transition: .2s;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

.program-selection {
  margin-top: -145px;
  margin-bottom: 40px; }
  .program-selection .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }

.program {
  width: 24.9%;
  color: #ffffff; }
  .program--green {
    background: #57C747; }
  .program--yellow {
    background: #FDC735; }
  .program--blue {
    background: #468DE5; }
  .program--purple {
    background: #E65AB3; }
  .program__img-container {
    height: 145px;
    max-width: 100%; }
  .program__info {
    padding: 25px 40px; }
  .program__heading {
    display: inline-block;
    margin-bottom: 9px;
    font-size: 19px;
    font-weight: bold; }
  .program__features-list {
    margin: 0;
    padding: 0; }
  .program__features-item {
    margin-bottom: 7px;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    line-height: 21px; }
    .program__features-item:last-child {
      margin-bottom: 0; }
  .program__img {
    display: block;
    max-width: 100%; }
  .program__link {
    color: #fff;
    opacity: .9;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .program__link:hover {
      opacity: 1;
      border-bottom: 1px solid rgba(255, 255, 255, 0.8); }

.about {
  margin-bottom: 17px; }

.our-feature {
  padding-left: 78px;
  position: relative;
  margin-bottom: 0; }
  .our-feature i {
    position: absolute;
    color: #57C747;
    font-size: 45px;
    margin-top: 5px;
    left: 19px;
    top: 0; }
  .our-feature__heading {
    margin-bottom: 13px;
    margin-top: 21px;
    font-size: 30px;
    line-height: 1;
    font-weight: 400; }
  .our-feature .text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400; }

.more-about {
  padding-top: 19px; }
  .more-about .heading {
    font-size: 30px;
    margin-bottom: 21px;
    line-height: 1;
    text-align: center;
    color: #000000;
    font-weight: 400; }
  .more-about__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px; }
  .more-about__text {
    margin-right: 36px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    color: #555555; }

.double-btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }

.double-btn {
  line-height: 1.72;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px 17px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #ffffff;
  color: #555555;
  font-size: 14px; }
  .double-btn i {
    margin-right: 7px; }
  .double-btn--left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .double-btn--right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none; }

.pride {
  border: 1px solid #57C747;
  text-align: center;
  padding: 0 60px 30px;
  margin-top: 73px; }
  .pride__heading {
    width: 220px;
    display: block;
    margin: -17px auto 25px;
    background: #ffffff;
    font-size: 20px;
    line-height: 30px;
    color: #000000; }
  .pride__years {
    font-size: 97px;
    line-height: 1;
    color: #000000;
    background: url("/img/about/stars.png") no-repeat;
    background-position: 95px 10px; }
    .pride__years--inner {
      background-position: 141px 10px; }
  .pride__text {
    padding-bottom: 22px;
    border-bottom: 1px solid #95989A;
    font-size: 18px;
    color: #555555;
    line-height: 27px; }
  .pride__get-free {
    display: block;
    margin-bottom: 27px;
    font-weight: bold;
    color: #000000;
    font-size: 24px;
    line-height: 27px; }
  .pride__btn {
    border-radius: 6px;
    padding: 12px 35px;
    font-size: 14px; }
    .pride__btn i {
      margin-right: 12px; }

.unique-center {
  margin-bottom: 56px; }
  .unique-center__heading {
    max-width: 360px;
    line-height: 30px;
    margin: auto;
    position: relative;
    padding-bottom: 50px;
    color: #000000;
    font-size: 25px;
    font-weight: 400;
    text-align: center; }
    .unique-center__heading:after {
      content: '';
      display: block;
      position: absolute;
      width: 82px;
      height: 24px;
      transform: translateX(-50%);
      left: 50%;
      bottom: 0;
      background: url("/img/unique-center-section/arrow-down.png") no-repeat center; }

.numbers-block {
  position: relative;
  padding: 60px 25px 131px;
  background: url(/img/unique-center-section/unique-center-bg.jpg) no-repeat;
  -webkit-clip-path: polygon(100% 0, 100% 92%, 50% 100%, 0 92%, 0 0, 50% 8%);
  clip-path: polygon(100% 0, 100% 92%, 50% 100%, 0 92%, 0 0, 50% 8%); }
  .numbers-block__heading, .play-and-learn__heading {
    margin-bottom: 70px;
    color: #ffffff;
    font-size: 49px;
    line-height: 1;
    text-align: center;
    position: relative;
    font-weight: 400; }
    .numbers-block__heading:after, .play-and-learn__heading:after {
      content: '';
      position: absolute;
      width: 100px;
      height: 6px;
      display: block;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
      background: #57C747; }
  .numbers-block__number {
    color: #ffffff;
    text-align: center; }
  .numbers-block__num {
    font-size: 100px;
    display: block;
    line-height: 1.29; }
  .numbers-block__num-text {
    max-width: 188px;
    margin: auto;
    display: block;
    font-size: 18px;
    line-height: 27px;
    font-family: Arial, sans-serif; }

.play-and-learn {
  margin-bottom: 105px; }
  .play-and-learn__heading {
    color: #000000;
    margin-bottom: 50px;
    margin-top: 0; }
  .play-and-learn__left .text, .play-and-learn__right .text {
    font-size: 18px;
    line-height: 1.2;
    text-align: center; }
  .play-and-learn__text {
    max-width: 700px;
    margin: 0 auto 34px;
    text-align: center;
    font-size: 18px; }

.video-block {
  position: relative;
  margin-bottom: 30px; }
  .video-block__inner {
    position: absolute;
    bottom: 30px;
    left: 30px; }
    .video-block__inner .fa {
      color: #57C747; }
  .video-block__img {
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%; }

.photo-block__img {
  display: block;
  height: 100%;
  width: auto;
  max-width: 100%; }

.photo-block__list {
  margin: -15px 0 15px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

.photo-block__list-item {
  margin: 15px;
  width: 151px;
  height: 151px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  cursor: pointer; }
  .photo-block__list-item:hover .photo-block__item-overlay, .photo-block__list-item:hover .gallery__item-overlay {
    opacity: 1; }

.photo-block__item-overlay, .gallery__item-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #57C747;
  border-radius: 5px;
  opacity: 0;
  transition: opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); }
  .photo-block__item-overlay i, .gallery__item-overlay i {
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff; }

.lessons {
  position: relative;
  padding: 85px 0 89px;
  margin-bottom: 70px;
  background: url("/img/lessons/lessons.jpg") no-repeat center;
  background-size: cover;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%);
  clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%); }
  .lessons__block {
    position: relative;
    padding-bottom: 100px;
    min-height: 400px; }
  .lessons__heading {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 300;
    position: relative;
    padding-bottom: 23px; }
    .lessons__heading:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 6px;
      display: block;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background: #57C747; }
  .lessons__list {
    color: #ffffff;
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .lessons__item {
    text-align: center;
    padding: 3px 0; }
    .lessons__item:first-child {
      padding-top: 0; }
    .lessons__item:last-child {
      padding-bottom: 0; }
  .lessons__link {
    font-size: 18px; }

.mega-reading {
  padding-bottom: 39px;
  border-bottom: 1px solid #95989A;
  overflow: hidden;
  background-position: 130% 0;
  background-size: contain; }
  .mega-reading__left {
    text-align: right; }
  .mega-reading__right {
    position: relative; }
    .mega-reading__right .mega-reading__text {
      margin: 0 0 0 100px;
      max-width: 300px;
      padding-left: 0;
      text-align: center;
      font-size: 18px;
      line-height: 27px; }
  .mega-reading__img {
    margin-left: 30px; }
  .mega-reading__heading {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 40px;
    margin-top: 31px; }
    .mega-reading__heading:before {
      content: '7 +';
      display: block;
      border-radius: 9px;
      padding: 5px 10px;
      position: absolute;
      top: -6px;
      right: -33px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      background: #57C747; }
    .mega-reading__heading:after {
      display: block;
      content: '';
      position: absolute;
      right: 0;
      bottom: -20px;
      width: 45px;
      height: 6px;
      background: #57C747; }
    .mega-reading__heading span {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #000000;
      font-size: 50px;
      line-height: 1.17;
      font-weight: 400;
      transition: .2s; }
      .mega-reading__heading span:hover {
        border-bottom: 1px solid #57C747;
        color: #57C747; }
  .mega-reading__text {
    padding-left: 140px;
    font-size: 18px;
    color: #555555;
    line-height: 27px;
    font-family: Arial, sans-serif; }
    .mega-reading__text--big {
      display: block;
      padding-left: 0;
      max-width: 400px;
      margin-left: auto;
      margin-bottom: 10px;
      color: #000000;
      font-size: 30px;
      line-height: 40px; }
  .mega-reading__promo-block {
    margin-left: auto; }

.promo-block {
  padding: 32px 28px 22px 8px;
  text-align: center;
  background: #F6F7F8;
  width: 85%;
  border-radius: 3px; }
  .promo-block__text {
    display: inline-block;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    margin-bottom: 4px; }
  .promo-block__tel {
    font-size: 22px; }
    .promo-block__tel .promo-block__text {
      font-size: 15px; }
    .promo-block__tel i {
      margin-right: 15px;
      color: #57C747; }
  .promo-block .btn--green {
    border-radius: 6px;
    position: relative;
    font-size: 14px;
    padding: 11px 36px;
    padding-left: 32px;
    min-width: 170px; }
    .promo-block .btn--green:hover {
      background-color: #7AD66C; }
  .promo-block .btn--transparent {
    margin-top: -6px;
    border-radius: 8px;
    color: #666666;
    border-color: #95989A;
    font-size: 14px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-color: rgba(0, 0, 0, 0.2);
    min-width: 170px; }

.doubled-btn-container {
  text-align: right; }

.mental-math {
  padding: 50px 0;
  padding-bottom: 100px; }
  .mental-math .promo-block .btn--green {
    padding: 11px 34px; }
  .mental-math__heading {
    position: relative;
    margin-bottom: 49px;
    margin-top: 23px; }
    .mental-math__heading:before {
      content: '4+';
      display: block;
      border-radius: 9px;
      padding: 3px 9px;
      position: absolute;
      top: -7px;
      right: 37px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      background: #57C747; }
    .mental-math__heading:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: -20px;
      width: 125px;
      height: 6px;
      background: #57C747; }
    .mental-math__heading span {
      font-size: 49px;
      line-height: 1;
      font-weight: 400;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      color: #000;
      transition: .2s; }
      .mental-math__heading span:hover {
        border-bottom: 1px solid #57C747;
        color: #57C747; }
  .mental-math__text, .mental-math__features-item {
    font-size: 18px;
    line-height: 27px;
    color: #555555;
    font-family: Arial, sans-serif; }
  .mental-math__sub-heading {
    font-size: 30px;
    line-height: 1;
    color: #000000;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px; }
  .mental-math__features-list {
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none; }
  .mental-math__features-item {
    line-height: 36px; }
    .mental-math__features-item i {
      margin-right: 12px;
      color: #57C747; }
  .mental-math__left {
    position: relative; }
    .mental-math__left .video {
      position: absolute;
      top: 60%;
      right: 20%; }
      .mental-math__left .video__btn {
        color: #57C747; }
    .mental-math__left .mental-math__text--bold {
      font-size: 30px;
      line-height: 30px;
      font-family: "Exo 2", sans-serif;
      text-align: center;
      color: #000000;
      margin-bottom: 10px; }
    .mental-math__left .mental-math__text, .mental-math__left .mental-math__features-item {
      text-align: center; }
  .mental-math__age {
    position: absolute;
    font-size: 216px;
    font-weight: 700;
    color: #ffffff;
    top: 0;
    right: 20%; }
  .mental-math__img {
    margin-left: -124%;
    display: block; }

.gallery {
  padding: 72px 0 184px;
  background: #f6f7f8;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%);
  clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%); }
  .gallery__heading, .reviews__heading {
    padding-bottom: 20px;
    position: relative;
    line-height: 1;
    font-weight: 400; }
    .gallery__heading:after, .reviews__heading:after {
      content: '';
      display: block;
      position: absolute;
      height: 6px;
      width: 45px;
      background: #57C747;
      bottom: -7px;
      left: 0; }
  .gallery__text, .reviews__text {
    font-size: 14px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    color: #555555; }
  .gallery__btn, .reviews__btn {
    padding: 9px 25px 11px 29px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    color: #555555;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-left: 20px; }
  .gallery__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .gallery__item {
    margin: 3px;
    width: 16%;
    position: relative;
    cursor: pointer; }
    .gallery__item:hover .gallery__item-overlay {
      opacity: 1; }
  .gallery__img {
    display: block;
    height: auto;
    width: 100%; }
  .gallery__all {
    text-align: right;
    padding-top: 19px; }

.reviews-container {
  margin-top: 56px;
  padding-bottom: 52px; }

.reviews {
  padding-top: 90px; }
  .reviews__heading {
    margin-bottom: 0;
    font-weight: 400; }
  .reviews__all {
    text-align: right; }
  .reviews__list {
    margin: 0;
    padding: 0;
    list-style: none; }

.review--text {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dddddd; }
  .review--text:last-child {
    border-bottom: 0; }

.review--video {
  margin: 10px 0; }
  .review--video:first-child {
    margin-top: 0; }
  .review--video:last-child {
    margin-bottom: 0; }

.review__name {
  display: inline-block;
  vertical-align: middle;
  color: #000000;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400; }

.review__info-btn {
  line-height: 30px;
  font-size: 20px;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 25px;
  color: #57C747;
  cursor: pointer; }

.review__text {
  font-size: 16px;
  color: #555555;
  line-height: 24px;
  font-family: Arial, sans-serif; }

.review__video {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

.review__video-left {
  width: 98px;
  margin-right: 20px; }

.review__video-right {
  width: 325px; }

.review__video-descr {
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
  color: #555555;
  line-height: 24px; }

.play-btn {
  border: none;
  background-color: transparent; }
  .play-btn i {
    color: #57C747;
    font-size: 115px; }

.footer {
  padding: 56px 0 40px;
  height: 310px;
  margin-top: -310px;
  position: relative;
  background: #3E3E3E; }
  .footer__social .social__item {
    width: 33%; }
  .footer__social .social__link {
    color: #CCCCCC; }
    .footer__social .social__link:hover {
      color: #ffffff; }
  .footer__sub-heading, .feed__heading {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    color: #CCCCCC;
    line-height: 16px;
    font-weight: bold;
    font-family: Arail, sans-serif; }
  .footer__link, .footer__address, .footer__tel, .feed__text, .copyrights__text {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: Arial, sans-serif;
    line-height: 14px;
    font-weight: 400;
    color: #CCCCCC; }
  .footer__address {
    display: inline-block; }
  .footer__tel {
    display: inline-block; }
  .footer .logo {
    width: 120px;
    margin-bottom: 20px; }
    .footer .logo__img {
      width: 40px;
      margin-right: 8px;
      opacity: .65; }
    .footer .logo__text {
      width: 71px;
      font-size: 11px;
      color: #CCCCCC;
      line-height: 11px; }

.feed__form {
  position: relative; }

.feed__text {
  line-height: 18px; }

.feed__input {
  width: 100%;
  display: block;
  border: none;
  background: #666666;
  padding: 13px 20px;
  color: #ffffff;
  outline: none;
  border-radius: 5px; }
  .feed__input::placeholder {
    color: #CCCCCC;
    font-family: Arial, sans-serif;
    font-weight: 400; }

.feed__submit {
  position: absolute;
  top: 8px;
  right: 5px;
  background: transparent;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 20px; }

.copyrights {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #95989A; }
  .copyrights__text {
    color: #737373;
    line-height: 18px; }
  .copyrights__right {
    text-align: right;
    font-size: 12px;
    font-family: Arial, sans-serif;
    line-height: 18px;
    font-weight: 400;
    color: #737373; }
    .copyrights__right .link {
      color: #737373;
      border-bottom: 1px solid rgba(115, 115, 115, 0.5); }
      .copyrights__right .link:hover {
        color: #ffffff;
        border-color: #ffffff; }

ymaps[class$="ground-pane"] {
  filter: url("data:image/svg+xml utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%); }

/* Chrome 19+ & Safari 6+ */
.inmap-content {
  position: absolute;
  top: 50px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px 0 20px 30px;
  left: 4vw;
  max-height: 85%; }

/*overflow-x: scroll */
#map {
  height: 682px !important; }

.map-info {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 105px;
  max-width: 370px;
  height: 100%;
  padding: 22px 22px 30px 30px; }
  .map-info__title {
    color: #57C747;
    font-size: 70px;
    font-weight: bold;
    line-height: 70px;
    margin-bottom: 10px; }
  .map-info__sity {
    font-size: 30px;
    line-height: 30px; }
  .map-info__list {
    padding: 0;
    margin-top: 22px; }
  .map-info__item {
    padding: 15px 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #95989A;
    font-size: 16px;
    line-height: 24px;
    font-family: arial; }

.inner-page-title {
  position: relative;
  color: #000;
  z-index: 5;
  margin-bottom: 0;
  font-size: 60px; }
  .inner-page-title:before {
    position: absolute;
    content: "";
    width: 341px;
    height: 4px;
    background-color: #D9D9D9;
    top: 50%;
    left: -15px;
    z-index: 5; }
  .inner-page-title:after {
    position: absolute;
    content: "";
    width: 341px;
    height: 4px;
    background-color: #D9D9D9;
    top: 50%;
    right: -15px;
    z-index: 5; }

.inner-top-section .logo {
  width: 302px; }

.inner-top-section .main-nav__list {
  padding: 22px 0 0 0; }

.inner-top-section .logo__img {
  width: 109px;
  fill: #000; }

.inner-top-section .top-info-block__text {
  color: #000; }

.inner-top-section .logo__text {
  color: #000;
  font-size: 35px; }

.inner-top-section .blackout {
  background-color: #fff;
  background-image: linear-gradient(to bottom, rgba(1, 44, 54, 0.1), rgba(85, 114, 120, 0));
  bottom: 139px; }

.inner-top-section .header {
  margin-bottom: 30px; }

.inner-top-section .fa-home {
  color: #000; }

.inner-top-section .bg-title {
  position: absolute;
  font-size: 100px;
  width: 100%;
  font-weight: bold;
  text-align: center;
  left: 0;
  color: #F7F7F7;
  top: -42px;
  z-index: 1;
  height: 85px;
  overflow: hidden; }

.inner-top-section .top-info-block {
  margin-bottom: 13px; }

.inner-top-section .title-wrapper {
  max-width: 100%;
  margin-bottom: 0; }
  .inner-top-section .title-wrapper .year-list {
    position: absolute;
    top: -60px;
    left: 40%;
    right: 0;
    z-index: 2; }
  .inner-top-section .title-wrapper .year__item {
    border-radius: 50%;
    border: none;
    opacity: .4;
    padding: 8px 10px 7px 12px;
    height: 40px;
    width: 40px; }
    .inner-top-section .title-wrapper .year__item a {
      color: #fff; }
    .inner-top-section .title-wrapper .year__item--green {
      background-color: #57C747; }
    .inner-top-section .title-wrapper .year__item--yellow {
      background-color: #FDC735; }
    .inner-top-section .title-wrapper .year__item--blue {
      background-color: #468DE5; }
    .inner-top-section .title-wrapper .year__item--purple {
      background-color: #E65AB3; }
    .inner-top-section .title-wrapper .year__item:hover {
      opacity: 1; }

.breadcrumbs {
  z-index: 5;
  margin-bottom: 45px; }
  .breadcrumbs__list {
    display: flex;
    list-style: none;
    justify-content: center; }
  .breadcrumbs__item:last-child .fa {
    display: none; }
  .breadcrumbs__item .fa-angle-right {
    color: #57C747; }
  .breadcrumbs__item .fa {
    padding: 0 8px; }
  .breadcrumbs__link {
    color: #555555; }

aside {
  background-color: #FAFAFA;
  padding-top: 135px;
  margin-top: -122px; }
  aside .pride {
    margin-top: 35px; }
    aside .pride__text {
      margin-top: 20px;
      font-size: 18px;
      line-height: 27px;
      padding-left: 35px;
      padding-right: 35px;
      margin-bottom: 25px; }
  aside .more-about {
    padding-top: 20px; }
    aside .more-about h2 {
      margin-top: 15px; }
    aside .more-about__inner {
      margin-bottom: 35px; }

blockquote {
  background-color: #F6F7F8;
  padding: 30px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-style: italic;
  line-height: 24px;
  font-size: 16px;
  font-family: "Arial", sans-serif; }

.inner-page-content h1 {
  font-size: 49px;
  line-height: 59px;
  font-family: "Exo 2", sans-serif;
  font-weight: normal;
  margin-bottom: 48px; }

.inner-page-content h2 {
  width: 100%;
  font-size: 30px;
  line-height: 30px;
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 30px; }

.inner-page-content a {
  color: #3EB12D;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: .3s; }
  .inner-page-content a:hover {
    color: #7AD66C;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5); }

.inner-page-content b {
  font-weight: bold; }

.inner-page-content p {
  font-family: Arail, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #555555; }

.inner-page-content ul {
  list-style: none;
  padding: 0;
  padding-left: 8px; }
  .inner-page-content ul li {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #555555;
    margin-bottom: 3px;
    padding-left: 30px; }
    .inner-page-content ul li:before {
      content: '\f105';
      position: absolute;
      display: block;
      width: 25px;
      height: 25px;
      color: #57C747;
      top: 5px;
      font-family: FontAwesome;
      left: 15px; }

.inner-page-content .pride__heading {
  background-color: #FAFAFA; }

.inner-page-content .column__title {
  position: relative; }

.inner-page-content .classes {
  padding-left: 0; }

.title-year-num {
  position: absolute;
  top: -5px;
  font-size: 12px;
  line-height: 14px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background-color: #FDC735;
  color: #fff; }

.left-column__title:before {
  content: '';
  width: 45px;
  height: 5px;
  bottom: -20px;
  position: absolute;
  left: 0;
  background-color: #57C747; }

.column__title {
  position: relative; }

.classes {
  list-style: none;
  margin-top: 19px;
  padding-left: 0;
  margin-bottom: 0; }
  .classes li {
    padding-top: 4px;
    padding-bottom: 5px; }
    .classes li a {
      font-size: 18px;
      line-height: 24px;
      font-family: "Exo 2", sans-serif; }
      .classes li a:hover {
        border-bottom: none;
        color: inherit; }
    .classes li .classes__description {
      font-size: 15px;
      font-family: Arail, sans-serif;
      color: #95989A;
      display: block; }
    .classes li a {
      color: #555555;
      border: none; }
    .classes li:hover {
      background-color: #EFEFEF;
      border-radius: 4px; }
  .classes .active {
    background-color: #57C747;
    box-shadow: 0 3px 0 #3EB12D;
    border-radius: 3px;
    color: #fff; }
    .classes .active .classes__description {
      color: #fff; }
    .classes .active a {
      color: #fff; }
    .classes .active:hover {
      background-color: #57C747; }

.right-column {
  padding-right: 50px;
  padding-left: 50px; }

.inner-page-bottom {
  background-color: #f6f7f8;
  padding-top: 30px;
  padding-bottom: 65px; }
  .inner-page-bottom__title {
    font-size: 30px;
    line-height: 30px;
    font-weight: normal;
    margin-bottom: 20px; }
  .inner-page-bottom__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0; }
    .inner-page-bottom__list:hover li {
      opacity: .7; }
    .inner-page-bottom__list li {
      padding: 4px 12px 5px 9px;
      border-radius: 3px;
      margin: 0 10px 10px 0;
      font-size: 13px;
      line-height: 18px;
      transition: .3s; }
      .inner-page-bottom__list li a {
        color: #fff; }
      .inner-page-bottom__list li:hover {
        margin-top: -1px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.19);
        opacity: 1; }

.green-item {
  background-color: #57C747; }

.blue-item {
  background-color: #468DE5; }

.yellow-item {
  background-color: #FDC735; }

.pink-item {
  background-color: #E65AB3; }

.year-list {
  width: 30%;
  list-style: none;
  padding: 0;
  display: flex;
  padding-top: 10px; }

.year__item {
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  padding: 3px 10px 5px 12px; }
  .year__item:hover {
    border: 1px solid #95989a; }
  .year__item a {
    color: #555555;
    font-weight: bold; }

.dropdown {
  position: relative; }
  .dropdown__title {
    width: 188px;
    background-color: #fff;
    border-radius: 4px;
    text-align: left;
    position: absolute;
    top: 100%;
    left: 74px;
    padding: 4px 0 6px 19px;
    display: none;
    z-index: 1; }

.dropdown-menu {
  display: none;
  list-style: non;
  position: absolute;
  top: 0;
  right: 0;
  float: left;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 0;
  border-right: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  width: 190px;
  border-top: none;
  z-index: 6; }

.dropdown.open .dropdown-menu,
.dropdown.open .dropdown__title {
  display: block; }

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  transition: .1s; }
  .dropdown-menu > li > a:hover {
    background-color: #57C747;
    color: #fff; }

.dropdown-toggle {
  z-index: 7;
  position: relative;
  width: 190px; }

ul.dropdown-menu::before {
  content: "Выберите город:";
  border-bottom: 1px #eee solid;
  width: 100%;
  display: block;
  padding: 50px 20px 10px;
  font-weight: bold;
  margin: 0 0 5px 0; }

/*==========  Desktop First  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .top-info-block__item {
    text-align: left; }
  .pride {
    padding: 0 20px 30px; }
  .pride__heading {
    font-size: 17px;
    width: 180px; }
  .pride__years {
    font-size: 70px;
    background-position: 87px 10px; }
  .review__name {
    font-size: 27px; }
  .review__video-right {
    width: 260px; }
  .play-btn i {
    font-size: 80px; }
  .review__video-left {
    width: 90px; } }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .lessons {
    background: url("/img/lessons/lessons@2x.jpg") no-repeat center;
    background-size: cover; }
  .numbers-block {
    background: url(/img/unique-center-section/unique-center-bg.jpg) no-repeat; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  /**/ }

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .program {
    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .program__info {
    padding: 15px; }
  .program__heading {
    margin-bottom: 15px; }
  .program__img-container {
    height: auto;
    margin-right: 20px; }
  .program__img {
    height: 100%;
    width: auto; }
  .pride__years {
    background: none; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  /**/ }

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  /**/ }

/*==========  Mobile First  ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  /**/ }

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  /**/ }

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  /**/ }

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  /**/ }

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  /**/ }
