.mega-reading {
	padding-bottom: 39px;
	border-bottom: 1px solid $colorGray;
	overflow: hidden;

	//background: url("../../img/mega-reading/open-book.png") no-repeat
	background-position: 130% 0;
	background-size: contain;

	&__left {
		text-align: right; }

	&__right {
		position: relative;
		.mega-reading__text {
			margin: 0 0 0 100px;
			max-width: 300px;
			padding-left: 0;
			text-align: center;
			font-size: $base-font-size + 2;
			line-height: 27px; } }


	&__img {
		margin-left: 30px; }

	&__heading {
		position: relative;
		padding-bottom: 5px;
		margin-bottom: 40px;
		margin-top: 31px;

		&:before {
			content: '7 +';
			display: block;
			border-radius: 9px;
			padding: 5px 10px;
			position: absolute;
			top: -6px;
			right: -33px;
			color: $colorWhite;
			font-size: $base-font-size - 4;
			font-weight: 700;
			background: $colorGreen; }

		&:after {
			display: block;
			content: '';
			position: absolute;
			right: 0;
			bottom: -20px;
			width: 45px;
			height: 6px;
			background: $colorGreen; }




		& span {
			border-bottom: 1px solid rgba(0, 0, 0, .1);
			color: $colorBlack;
			font-size: 50px;
			line-height: 1.17;
			font-weight: 400;
			transition: .2s;
			&:hover {
				border-bottom: 1px solid $colorGreen;
				color: $colorGreen; } } }

	&__text {
		padding-left: 140px;
		font-size: $base-font-size + 2;
		color: $colorTextColor;
		line-height: 27px;
		font-family: Arial, sans-serif;

		&--big {
			display: block;
			padding-left: 0;
			max-width: 400px;
			margin-left: auto;
			margin-bottom: 10px;
			color: $colorBlack;
			font-size: 30px;
			line-height: 40px; } }

	&__promo-block {
		margin-left: auto; } }

.promo-block {
	padding: 32px 28px 22px 8px;
	text-align: center;
	background: #F6F7F8;
	width: 85%;
	border-radius: 3px;


	&__text {
		display: inline-block;
		font-size: 22px;
		font-weight: 700;
		line-height: 27px;
		margin-bottom: 4px; }

	&__tel {
		font-size: 22px;


		& .promo-block__text {
			font-size: $base-font-size - 1; }

		& i {
			margin-right: 15px;
			color: $colorGreen; } }

	& .btn--green {
		border-radius: 6px;
		position: relative;
		font-size: $base-font-size - 2;
		padding: 11px 36px;
		padding-left: 32px;
		min-width: 170px;

		&:hover {
			background-color: #7AD66C; } }

	& .btn--transparent {
		margin-top: -6px;
		border-radius: 8px;
		color: #666666;
		border-color: $colorGray;
		font-size: $base-font-size - 2;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-color: rgba(0, 0, 0, .2);
		min-width: 170px; } }

.doubled-btn-container {
	text-align: right; }
