aside {
	background-color: #FAFAFA;
	padding-top: 135px;
	margin-top: -122px;
	.pride {
		margin-top: 35px;
		&__text {
			margin-top: 20px;
			font-size: 18px;
			line-height: 27px;
			padding-left: 35px;
			padding-right: 35px;
			margin-bottom: 25px; } }
	& .more-about {
		padding-top: 20px;
		h2 {
			margin-top: 15px; }
		&__inner {
			margin-bottom: 35px; } } }
