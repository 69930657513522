ymaps[class$="ground-pane"] {
  filter: url("data:image/svg+xml utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%); }
/* Chrome 19+ & Safari 6+ */

.inmap-content {
  position: absolute;
  top: 50px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.75);
  padding: 20px 0 20px 30px;
  left: 4vw;
  max-height: 85%; }
/*overflow-x: scroll */

#map {
	height: 682px !important; }

.map-info {
	background-color: rgba(255, 255, 255, .8);
	position: absolute;
	top: 0;
	left: 105px;
	max-width: 370px;
	height: 100%;
	padding: 22px 22px 30px 30px;

	&__title {
		color: #57C747;
		font-size: 70px;
		font-weight: bold;
		line-height: 70px;
		margin-bottom: 10px; }

	&__sity {
		font-size: 30px;
		line-height: 30px; }

	&__list {
		padding: 0;
		margin-top: 22px; }

	&__item {
		padding: 15px 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		border-top: 1px solid #95989A;
		font-size: 16px;
		line-height: 24px;
		font-family: arial; } }
