.unique-center {
	margin-bottom: 56px;

	&__heading {
		max-width: 360px;
		line-height: 30px;
		margin: auto;
		position: relative;
		padding-bottom: 50px;

		color: $colorBlack;
		font-size: 25px;
		font-weight: 400;
		text-align: center;
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 82px;
			height: 24px;
			transform: translateX(-50%);
			left: 50%;
			bottom: 0;

			background: url('/img/unique-center-section/arrow-down.png') no-repeat center; } } }


.numbers-block {
	position: relative;
	padding: 60px 25px 131px;
	background: url(/img/unique-center-section/unique-center-bg.jpg) no-repeat;
	-webkit-clip-path: polygon(100% 0, 100% 92%, 50% 100%, 0 92%, 0 0, 50% 8%);
	clip-path: polygon(100% 0, 100% 92%, 50% 100%, 0 92%, 0 0, 50% 8%);

	//&:before
	//	content: ''
	//	z-index: 2
	//	position: absolute
	//	top: -3px
	//	left: 0
	//	right: 0
	//	display: block
	//	width: 100%
	//	min-height: 50px
	//	max-height: 72px
	//	background: transparent url('../../img/corner-white-top.svg') no-repeat center
	//	background-size: 100% auto
	//
	//&:after
	//	content: ''
	//	z-index: 2
	//	position: absolute
	//	bottom: -3px
	//	left: 0
	//	right: 0
	//	display: block
	//	width: 100%
	//	height: 50px
	//	background: transparent url('../../img/corner-white-bottom.svg') no-repeat center


	&__heading {
		margin-bottom: 70px;
		color: $colorWhite;
		font-size: 49px;
		line-height: 1;
		text-align: center;
		position: relative;
		font-weight: 400;

		&:after {
			content: '';
			position: absolute;
			width: 100px;
			height: 6px;
			display: block;
			left: 50%;
			bottom: -20px;
			transform: translateX(-50%);
			background: $colorGreen; } }

	&__number {
		color: $colorWhite;
		text-align: center; }

	&__num {
		font-size: 100px;
		display: block;
		line-height: 1.29; }

	&__num-text {
		max-width: 188px;
		margin: auto;
		display: block;
		font-size: $base-font-size + 2;
		line-height: 27px;
		font-family: Arial, sans-serif; } }
