.inner-page-content {
	& h1 {
		font-size: 49px;
		line-height: 59px;
		font-family: "Exo 2", sans-serif;
		font-weight: normal;
		margin-bottom: 48px; }
	& h2 {
		width: 100%;
		font-size: 30px;
		line-height: 30px;
		font-weight: normal;
		margin-bottom: 5px;
		margin-top: 30px; }
	& a {
		color: #3EB12D;
		border-bottom: 1px solid rgba(0, 0, 0, .1);
		transition: .3s;
		&:hover {
			color: #7AD66C;
			border-bottom: 1px solid rgba(0, 0, 0, .5); } }
	& b {
		font-weight: bold; }

	& p {
		font-family: Arail, sans-serif;
		font-size: 14px;
		line-height: 21px;
		color: #555555; }

	& ul {
		list-style: none;
		padding: 0;
		padding-left: 8px;
		& li {
			position: relative;
			font-size: 16px;
			line-height: 28px;
			color: #555555;
			margin-bottom: 3px;
			padding-left: 30px;
			&:before {
				content: '\f105';
				position: absolute;
				display: block;
				width: 25px;
				height: 25px;
				color: $colorGreen;
				top: 5px;
				font-family: FontAwesome;
				left: 15px; } } }

	& .pride__heading {
		background-color: #FAFAFA; }

	& .column__title {
		position: relative; }

	.classes {
		padding-left: 0; } }

.title-year-num {
	position: absolute;
	top: -5px;
	font-size: 12px;
	line-height: 14px;
	padding: 5px 10px 5px 10px;
	border-radius: 5px;
	background-color: $colorYellow;
	color: #fff; }

.left-column__title {
	&:before {
		content: '';
		width: 45px;
		height: 5px;
		bottom: -20px;
		position: absolute;
		left: 0;
		background-color: $colorGreen; } }

.column__title {
	position: relative; }


.classes {
	list-style: none;
	margin-top: 19px;
	padding-left: 0;
	margin-bottom: 0;
	& li {
		padding-top: 4px;
		padding-bottom: 5px;
		& a {
			font-size: 18px;
			line-height: 24px;
			font-family: "Exo 2", sans-serif;
			&:hover {
				border-bottom: none;
				color: inherit; } }

		& .classes__description {
			font-size: 15px;
			font-family: Arail, sans-serif;
			color: #95989A;
			display: block; }
		& a {
			color: $colorTextColor;
			border: none; }
		&:hover {
			background-color: #EFEFEF;
			border-radius: 4px; } }
	& .active {
		background-color: $colorGreen;
		box-shadow: 0 3px 0 #3EB12D;
		border-radius: 3px;
		color: #fff;
		& .classes__description {
			color: #fff; }
		& a {
			color: #fff; }
		&:hover {
			background-color: $colorGreen; } } }

.right-column {
	padding-right: 50px;
	padding-left: 50px; }
