.about {
	margin-bottom: 17px; }

.our-feature {
	padding-left: 78px;
	position: relative;
	margin-bottom: 0;

	& i {
		position: absolute;
		color: $colorGreen;
		font-size: 45px;
		margin-top: 5px;
		left: 19px;
		top: 0; }

	&__heading {
		margin-bottom: 13px;
		margin-top: 21px;
		font-size: 30px;
		line-height: 1;
		font-weight: 400; }

	& .text {
		font-size: $base-font-size;
		line-height: 24px;
		font-weight: 400; } }

.more-about {
	padding-top: 19px;

	& .heading {
		font-size: 30px;
		margin-bottom: 21px;
		line-height: 1;
		text-align: center;
		color: $colorBlack;
		font-weight: 400; }

	&__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 60px; }

	&__text {
		margin-right: 36px;
		font-size: $base-font-size - 2;
		font-family: Arial, sans-serif;
		font-weight: 400;
		color: $colorTextColor; } }

.double-btn-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center; }

.double-btn {
	//width: 50%
	line-height: 1.72;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 10px 17px;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 5px;
	background: $colorWhite;
	color: $colorTextColor;
	font-size: $base-font-size - 2;

	& i {
		margin-right: 7px; }

	&--left {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0; }

	&--right {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: none; } }

.pride {
	border: 1px solid $colorGreen;
	text-align: center;
	padding: 0 60px 30px;
	margin-top: 73px;

	&__heading {
		width: 220px;
		display: block;
		margin: -17px auto 25px;
		background: $colorWhite;

		font-size: $base-font-size + 4;
		line-height: 30px;
		color: $colorBlack; }

	&__years {
		font-size: 97px;
		line-height: 1;
		color: $colorBlack;
		background: url("/img/about/stars.png") no-repeat;
		background-position: 95px 10px;
		&--inner {
		  background-position: 141px 10px; } }

	&__text {
		padding-bottom: 22px;
		border-bottom: 1px solid $colorGray;
		font-size: $base-font-size + 2;
		color: $colorTextColor;
		line-height: 27px; }

	&__get-free {
		display: block;
		margin-bottom: 27px;
		font-weight: bold;
		color: $colorBlack;
		font-size: $base-font-size + 8;
		line-height: 27px; }

	&__btn {
		border-radius: 6px;
		padding: 12px 35px;
		font-size: $base-font-size - 2;

		& i {
			margin-right: 12px; } } }
