.top-section {
	padding-bottom: 250px;
	background: url('/img/bg-main.jpg') no-repeat center;
	background-size: cover; }

.top-info-block {
	//margin-bottom: 50px
	margin-bottom: 56px;

	&__item {
		text-align: right; }

	&__link {
		padding-top: 14px;
		display: block;
		font-size: 15px !important;
		line-height: 1;

		& .fa {}
		vertical-align: text-bottom;
		font-size: $base-font-size + 1;
		color: $colorGreen;

		&--btn {
			& .fa {
				color: $colorWhite; } } }

	&__text {
		margin-left: 16px;
		font-size: $base-font-size - 1;
		color: $colorWhite;
		transition: .3s;
		&:hover {
			color: $colorGreen; } }

	& .btn {
		margin-left: auto;
		& .fa-map-marker {
			margin-right: 13px; }
		& .btn__text {
			margin-right: 9px; } } }

.header {
	margin-bottom: 46px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between; }

.logo {
	width: 260px;

	&__link {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center; }

	&__img {
		width: 80px;
		margin-right: 20px; }

	&__text {
		display: inline-block;
		width: 160px;
		font-size: 25px;
		line-height: 1;
		text-transform: uppercase;
		color: $colorWhite;
		font-weight: bold; } }

.main-nav {
	&__list {
		padding: 8px 0 0 0;
		margin: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap; }

	&__item {
		margin: 0 4px;
		&:last-child {
			margin-right: 0; }
		&:first-child {
			margin-left: 0; } }

	&__link {
		display: block;
		padding: 5px 13px 5px 13px !important;
		line-height: 17px;
		font-size: $base-font-size - 1;
		&.active {
			background: $colorGreen;
			color: $colorWhite !important;
			box-shadow: 0 3px 0 $colorDeepGreen;
			border-radius: 3px;
			& span {
				border: none; } } }
	& .dropdown {
		z-index: 7;
		&-toggle {
			width: auto;
			&:focus {
				outline: none; } }
		&-menu {
			top: 100%;
			left: 0;
			right: auto;
			width: auto;
			&:before {
				display: none; } } } }

.title-wrapper {
	max-width: 650px;
	margin: 0 auto 30px;
	text-align: center;
	position: relative; }

.social-video-block {
	max-width: 650px;
	padding-left: 51px;
	margin: auto;
	display: flex;
	flex-wrap: wrap; }

.social {
	width: 191px;
	margin-right: 41px;
	display: flex;
	align-items: center;

	&__list {
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between; }

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: rgba(255, 255, 255, .2);
		color: $colorWhite;
		transition: .2s;

		&:hover {
			background: $colorGreen;
			color: #000000 $colorWhite; } } }

.video {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&__link {
		display: flex;
		flex-wrap: wrap;
		align-items: center; }

	&__text a {
		color: #fff;
		border-bottom-color: rgba(255, 255, 255, .8); }

	&:hover .video__text span {
		color: $colorGreen;
		border-bottom-color: $colorGreen; }

	//&:hover .fa-play-circle
	//	transition: .2s
	//	color: $colorGreen



	&__btn {
		margin-right: 25px;
		color: $colorWhite;
		& a i {
			font-size: 96px;
			line-height: 96px;
			color: $colorWhite; } }

	&__text {
		font-size: $base-font-size - 1;
		line-height: 23px;
		max-width: 138px;
		color: $colorWhite;
		& span {
			transition: .2s;
			border-bottom: 1px solid rgba(255, 255, 255, .3); } } }
