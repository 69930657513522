@import "vars";

/*==========  Desktop First  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	.top-info-block__item {
		text-align: left; }
	.pride {
		padding: 0 20px 30px; }
	.pride__heading {
		font-size: 17px;
		width: 180px; }
	.pride__years {
		font-size: 70px;
		background-position: 87px 10px; }

	.review__name {
		font-size: 27px; }
	.review__video-right {
		width: 260px; }

	.play-btn i {
		font-size: 80px; }

	.review__video-left {
		width: 90px; } }


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.lessons {
		background: url('/img/lessons/lessons@2x.jpg') no-repeat center;
		background-size: cover; }
	.numbers-block {
		background: url(/img/unique-center-section/unique-center-bg.jpg) no-repeat; } }


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	.program {
		width: 85%;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start; }

	.program__info {
		padding: 15px; }

	.program__heading {
		margin-bottom: 15px; }

	.program__img-container {
		height: auto;
		margin-right: 20px; }

	.program__img {
		height: 100%;
		width: auto; }

	.pride__years {
		background: none; } }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	/**/ }


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }

