.inner-page-bottom {
	background-color: #f6f7f8;
	padding-top: 30px;
	padding-bottom: 65px;

	&__title {
		font-size: 30px;
		line-height: 30px;
		font-weight: normal;
		margin-bottom: 20px; }

	&__list {
		list-style: none;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		margin-top: 0;
		&:hover li {
			opacity: .7; }
		& li {
			padding: 4px 12px 5px 9px;
			border-radius: 3px;
			margin: 0 10px 10px 0;
			font-size: 13px;
			line-height: 18px;
			transition: .3s;
			& a {
				color: #fff; }
			&:hover {
				margin-top: -1px;
				box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.19);
				opacity: 1; } } } }
.green-item {
	background-color: $colorGreen; }

.blue-item {
	background-color: $colorBlue; }

.yellow-item {
	background-color: $colorYellow; }

.pink-item {
	background-color: $colorPurple; }

.year-list {
	width: 30%;
	list-style: none;
	padding: 0;
	display: flex;
	padding-top: 10px; }

.year__item {
	margin-right: 10px;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	padding: 3px 10px 5px 12px;
	&:hover {
		border: 1px solid #95989a; }
	& a {
		color: $colorTextColor;
		font-weight: bold; } }
