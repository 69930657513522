.gallery {
	padding: 72px 0 184px;
	background: #f6f7f8;
	-webkit-clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%);
	clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%);

	&__heading {
		padding-bottom: 20px;
		position: relative;
		line-height: 1;
		font-weight: 400;

		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 6px;
			width: 45px;
			background: $colorGreen;

			bottom: -7px;
			left: 0; } }

	&__text {
		font-size: $base-font-size - 2;
		font-family: Arial, sans-serif;
		font-weight: 400;
		color: $colorTextColor; }

	&__btn {
		padding: 9px 25px 11px 29px;
		font-size: $base-font-size - 2;
		font-family: Arial, sans-serif;
		font-weight: 400;
		color: $colorTextColor;
		border: 1px solid rgba(0, 0, 0, .2);
		border-radius: 5px;
		margin-left: 20px; }

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around; }

	&__item {
		margin: 3px;
		width: 16%;
		position: relative;
		cursor: pointer;
		&:hover .gallery__item-overlay {
			opacity: 1; } }

	&__img {
		display: block;
		height: auto;
		width: 100%; }

	&__item-overlay {
		@extend .photo-block__item-overlay; }

	&__all {
		text-align: right;
		padding-top: 19px; } }
