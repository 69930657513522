.program-selection {
	margin-top: -145px;
	margin-bottom: 40px;

	.container {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start; } }

.program {
	width: 24.9%;
	color: $colorWhite;

	&--green {
		background: $colorGreen; }
	&--yellow {
		background: $colorYellow; }
	&--blue {
		background: $colorBlue; }
	&--purple {
		background: $colorPurple; }

	&__img-container {
		height: 145px;
		max-width: 100%; }

	&__info {
		padding: 25px 40px; }

	&__heading {
		display: inline-block;
		margin-bottom: 9px;
		font-size: $base-font-size + 3;
		font-weight: bold; }

	&__features-list {
		margin: 0;
		padding: 0; }

	&__features-item {
		margin-bottom: 7px;
		font-family: $arial-font;
		font-size: $base-font-size - 2;
		line-height: 21px;
		&:last-child {
			margin-bottom: 0; } }

	&__img {
		display: block;
		max-width: 100%; }

	&__link {
		color: #fff;
		opacity: .9;
		border-bottom: 1px solid rgba(255, 255, 255, .3);
		&:hover {
			opacity: 1;
			border-bottom: 1px solid rgba(255, 255, 255, .8); } } }
