.inner-page-title {
	position: relative;
	color: #000;
	z-index: 5;
	margin-bottom: 0;
	font-size: 60px;
	&:before {
		position: absolute;
		content: "";
		width: 341px;
		height: 4px;
		background-color: #D9D9D9;
		top: 50%;
		left: -15px;
		z-index: 5; }
	&:after {
		position: absolute;
		content: "";
		width: 341px;
		height: 4px;
		background-color: #D9D9D9;
		top: 50%;
		right: -15px;
		z-index: 5; } }

.inner-top-section {
	& .logo {
		width: 302px; }

	& .main-nav__list {
		padding: 22px 0 0 0; }

	& .logo__img {
		width: 109px;
		fill: #000; }

	& .top-info-block__text {
		color: #000; }

	& .logo__text {
		color: #000;
		font-size: 35px; }

	& .blackout {
		background-color: #fff;
		background-image: linear-gradient(to bottom, rgba(1, 44, 54, .1), rgba(85, 114, 120, 0));
		bottom: 139px; }

	& .header {
		margin-bottom: 30px; }

	& .fa-home {
		color: #000; }

	& .bg-title {
		position: absolute;
		font-size: 100px;
		width: 100%;
		font-weight: bold;
		text-align: center;
		left: 0;
		color: #F7F7F7;
		top: -42px;
		z-index: 1;
		height: 85px;
		overflow: hidden; }

	& .top-info-block {
		margin-bottom: 13px; }

	& .title-wrapper {
		max-width: 100%;
		margin-bottom: 0;

		& .year-list {
			position: absolute;
			top: -60px;
			left: 40%;
			right: 0;
			z-index: 2; }
		& .year__item {
			border-radius: 50%;
			border: none;
			opacity: .4;
			padding: 8px 10px 7px 12px;
			height: 40px;
			width: 40px;
			& a {
				color: #fff; }
			&--green {
				background-color: $colorGreen; }
			&--yellow {
				background-color: $colorYellow; }
			&--blue {
				background-color: $colorBlue; }
			&--purple {
				background-color: $colorPurple; }
			&:hover {
				opacity: 1; } } } }
