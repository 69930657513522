.mental-math {
	padding: 50px 0;
	padding-bottom: 100px;

	& .promo-block .btn--green {
		padding: 11px 34px; }

	&__heading {
		position: relative;
		margin-bottom: 49px;
		margin-top: 23px;

		&:before {
			content: '4+';
			display: block;
			border-radius: 9px;
			padding: 3px 9px;
			position: absolute;
			top: -7px;
			right: 37px;
			color: $colorWhite;
			font-size: $base-font-size - 4;
			font-weight: 700;
			background: $colorGreen; }

		&:after {
			display: block;
			content: '';
			position: absolute;
			left: 0;
			bottom: -20px;
			width: 125px;
			height: 6px;
			background: $colorGreen; }

		& span {
			font-size: 49px;
			line-height: 1;
			font-weight: 400;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			color: #000;
			transition: .2s;
			&:hover {
				border-bottom: 1px solid $colorGreen;
				color: $colorGreen; } } }

	&__text {
		font-size: $base-font-size + 2;
		line-height: 27px;
		color: $colorTextColor;
		font-family: Arial, sans-serif; }


	&__sub-heading {
		font-size: 30px;
		line-height: 1;
		color: $colorBlack;
		font-weight: 400;
		margin-top: 0;
		margin-bottom: 15px; }

	&__features-list {
		margin: 0 0 30px 0;
		padding: 0;
		list-style: none; }

	&__features-item {
		@extend .mental-math__text;
		line-height: 36px;

		& i {
			margin-right: 12px;
			color: $colorGreen; } }

	&__left {
		position: relative;

		& .video {
			position: absolute;
			top: 60%;
			right: 20%;
			&__btn {
				color: $colorGreen; } }

		& .mental-math__text--bold {
			font-size: 30px;
			line-height: 30px;
			font-family: $default-font;
			text-align: center;
			color: $colorBlack;
			margin-bottom: 10px; }

		& .mental-math__text {
			text-align: center; } }


	&__age {
		position: absolute;
		font-size: 216px;
		font-weight: 700;
		color: $colorWhite;
		top: 0;
		right: 20%; }

	&__img {
		margin-left: -124%;
		display: block; } }

