$default-font: 'Exo 2', sans-serif;
$arial-font: 'Arial', sans-serif;

$base-font-size: 16px;

$colorWhite: #ffffff;
$colorBlack: #000000;
$colorGreen: #57C747;
$colorDeepGreen: #3EB12D;
$colorYellow: #FDC735;
$colorBlue: #468DE5;
$colorPurple: #E65AB3;
$colorTextColor: #555555;
$colorGray: #95989A;
